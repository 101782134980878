import React from 'react';
import styled from 'styled-components';
import {hover, Transition, white} from '@/styles/globalStyleVars';

/**
 * Hamburger Icon component
 * Pass the props with your data to the Hamburger Icon.
 **/
const CloseIconSearch = () => {
  return (
    <StyledCloseIconSearch>
      <div className="CloseIconSearchSvg">
        <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g id="Ellipse_389" data-name="Ellipse 389" fill="none" stroke="#363231" strokeWidth="1" opacity="0.15">
            <circle cx="20" cy="20" r="20" stroke="none"/>
            <circle cx="20" cy="20" r="19.5" fill="none"/>
          </g>
          <circle id="Ellipse_390" data-name="Ellipse 390" cx="20" cy="20" r="0" fill="#fb5400"/>
          <g id="Group_24474" data-name="Group 24474" transform="translate(-4820.5 83.5)">
            <line id="Line_7" data-name="Line 7" x2="5" y2="5" transform="translate(4838 -66)" fill="none" stroke="#363231" strokeLinecap="round" strokeWidth="1"/>
            <line id="Line_8" data-name="Line 8" y1="5" x2="5" transform="translate(4838 -66)" fill="none" stroke="#363231" strokeLinecap="round" strokeWidth="1"/>
          </g>
        </svg>

      </div>


    </StyledCloseIconSearch>
  );
};
/**
 * Styled component for Hamburger Icon
 * Add your styles for Hamburger Icon component here.
 **/
const StyledCloseIconSearch = styled.div`
  .CloseIconSearchSvg {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    transition: 0.7s all ease;
    cursor: pointer;
    
    #Ellipse_390, line{
      transition: 0.6s all ${Transition};
    }
    
    &:hover{
      #Ellipse_390{
        r: 20px;
      }
      line{
        stroke: ${white};
      }
    }
  }
`;

export default React.memo(CloseIconSearch);
